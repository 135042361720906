enum EntityRoutes {
  admin = 'admin',
  user = 'users',
  role = 'role',
  translation = 'translation',
  baseData = 'base-data',
  setting = 'setting',
}

enum HelperRoutes {
  profile = 'profile',
  config = 'config',
}

enum GeneralRoutes {
  detail = 'detail',
  view = 'view',
  add = 'add',
  edit = 'edit',
}

enum AuthRoutes {
  auth = 'auth',
  login = 'login',
  operatorLogin = 'operator-login',
}

enum AdministratorRoutes {
  dashboard = 'admin-dashboard',
  stationsDashboard = 'stations',
  disruptions = 'disruptions',
}

enum OperatorRoutes {
  orderOperations = 'order-operations',
}

export type AppRoutes = typeof AppRoutes;
export const AppRoutes = {
  ...EntityRoutes,
  ...GeneralRoutes,
  ...HelperRoutes,
  ...AuthRoutes,
  ...AdministratorRoutes,
  ...OperatorRoutes,
};
